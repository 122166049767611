<template>
  <div class="app-body home">
    <index-sjzx-vue></index-sjzx-vue>
    <div class="a-flex-rsbfs a-mt-16 home-item">
      <index-yye-vue></index-yye-vue>
      <index-byshyyeph-vue></index-byshyyeph-vue>
    </div>
  </div>
</template>

<script>
import indexByshyyephVue from "./child/index-byshyyeph.vue";
import indexSjzxVue from "./child/index-sjzx.vue";
import indexYyeVue from "./child/index-yye.vue";

export default {
  components: {
    indexSjzxVue,
    indexYyeVue,
    indexByshyyephVue,
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.home {
  margin-top: 16px;
  overflow-y: auto;
  padding-bottom: 32px;

  &-item {
    height: 344px;
    // overflow-y: auto;

    > * {
      height: 100%;
      // overflow-y: auto;

      &:nth-of-type(1) {
        width: 66%;
      }

      &:nth-of-type(2) {
        width: 33%;
      }
    }
  }
}

/deep/ .indexbox {
  padding: 20px 24px;
  background: #fff;
  border-radius: 4px;
}
</style>
