<template>
  <div class="indexbox">
    <div class="a-flex-rsbc">
      <div class="a-fs-16 a-fw-500 a-ff-pmp">营业额</div>
      <el-date-picker
        v-model="time"
        type="month"
        placeholder="选择月"
        :picker-options="pickerNormalOptions"
        @change="getTimeValue"
      ></el-date-picker>
    </div>
    <le-echarts-line
      :echarts-obj="echartsObj"
      style="height: 258px; width: 100%; margin-top: 14px"
    ></le-echarts-line>
  </div>
</template>

<script>
import util from "../../../utils/util.js";
export default {
  data() {
    return {
      time: new Date(),
      pickerNormalOptions: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      startTime: "",
      endTime: "",
      echartsObj: {
        id: "indexEcharts",
        xAxisData: [],
        seriesData0: [],
        seriesData1: [],
        seriesData2: [],
        seriesName1: "订单数量",
        seriesName2: "订单金额",
        seriesUnit1: "笔",
        seriesUnit2: "元",
        echartsType: "energyList",
        yName: "(元/笔)",
      },
    };
  },
  mounted() {
    this.getTimeValue();
  },
  methods: {
    //获取营业额数据
    handlerGetStat() {
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.getStatEnergyList,
          params: {
            queryStartDate: this.startTime,
            queryEndDate: this.endTime,
            statIncome: 1,
          },
        })
        .then((res) => {
          const that = this;
          if (res.result.code == 0) {
            if (res.result.data) {
              that.echartsObj.xAxisData = [];
              that.echartsObj.seriesData1 = [];
              that.echartsObj.seriesData2 = [];
              res.result.data.map((item) => {
                that.echartsObj.xAxisData.push(item["fullDateText"]);
                that.echartsObj.seriesData1.push(item["orderNum"]);
                that.echartsObj.seriesData2.push(item["orderAmount"] / 100);
              });
            }
          } else {
            this.$message.error(res.result.msg);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    getTimeValue(time) {
      const date = time ? time : new Date();
      const y = date.getFullYear(),
        m = date.getMonth();
      this.startTime = util.easyformatDate(new Date(y, m, 1));

      this.endTime = util.easyformatDate(new Date(y, m + 1, 0));
      this.handlerGetStat();
    },
  },
};
</script>
