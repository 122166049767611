<template>
  <div class="indexbox">
    <div class="a-flex-rsbfs">
      <div class="a-fs-16 a-fw-500 a-w-100 a-ff-pmp">数据中心</div>
      <le-date-change-time-index
        :active-t.sync="activeT"
        :start-time.sync="startTime"
        :end-time.sync="endTime"
      ></le-date-change-time-index>
    </div>
    <div class="a-flex-rfsc indexbox-top">
      <div class="indexbox-top-item a-flex-rsbc">
        <div class="a-flex-csbfs a-h-100" style="width: 40%">
          <span class="a-c-normal a-fs-14">总营业额(元)</span>
          <div class="a-fs-18 a-fw-700 a-mtb-12 a-w-100" style="word-wrap: break-word">
            &yen;
            <span class="a-fs-24 a-ml-5 a-ff-db" style="line-height: 32px">
              {{ info.orderAmount }}
            </span>
          </div>
          <div class="a-flex-rsbc">
            <div class="a-flex-rfsc a-fs-12">
              <span class="a-c-second">同比</span>
              <img
                v-if="info.compareOrderAmount2 < 0"
                src="../../../assets/icon/index-top-down.png"
                alt=""
                class="colortip"
              />
              <img v-else src="../../../assets/icon/index-top-up.png" alt="" class="colortip" />
              <span :class="info.compareOrderAmount2 < 0 ? 'a-c-red' : 'a-c-green'">
                {{ Math.abs(info.compareOrderAmount2) || 0 }}%
              </span>
            </div>
            <div class="a-flex-rfsc a-fs-12 a-ml-16">
              <span class="a-c-second">环比</span>

              <img
                v-if="info.compareOrderAmount < 0"
                src="../../../assets/icon/index-top-down.png"
                alt=""
                class="colortip"
              />
              <img v-else src="../../../assets/icon/index-top-up.png" alt="" class="colortip" />
              <span :class="info.compareOrderAmount < 0 ? 'a-c-red' : 'a-c-green'">
                {{ Math.abs(info.compareOrderAmount) || 0 }}%
              </span>
            </div>
          </div>
        </div>
        <div class="a-flex-rfec" style="width: 60%">
          <div ref="echartsRef" style="width: 100%; height: 125px"></div>
          <div class="borderline"></div>
        </div>
      </div>
      <div class="indexbox-top-item a-flex-rsbc">
        <div class="a-flex-csbfs a-h-100 a-ml-24">
          <span class="a-c-normal a-fs-14">总订单量(笔)</span>
          <div class="a-fs-18 a-fw-700 a-mtb-12">
            <span class="a-fs-24 a-ff-db">{{ totalNum.orderNum || 0 }}</span>
          </div>
          <div class="a-flex-rsbc">
            <div class="a-flex-rfsc a-fs-12">
              <span class="a-c-second">同比</span>
              <img
                v-if="totalNum.compareOrderNum2 < 0"
                src="../../../assets/icon/index-top-down.png"
                alt=""
                class="colortip"
              />
              <img v-else src="../../../assets/icon/index-top-up.png" alt="" class="colortip" />
              <span :class="totalNum.compareOrderNum2 < 0 ? 'a-c-red' : 'a-c-green'">
                {{ Math.abs(totalNum.compareOrderNum2) || 0 }}%
              </span>
            </div>
            <div class="a-flex-rfsc a-fs-12 a-ml-16">
              <span class="a-c-second">环比</span>

              <img
                v-if="totalNum.compareOrderNum < 0"
                src="../../../assets/icon/index-top-down.png"
                alt=""
                class="colortip"
              />
              <img v-else src="../../../assets/icon/index-top-up.png" alt="" class="colortip" />
              <span :class="totalNum.compareOrderNum < 0 ? 'a-c-red' : 'a-c-green'">
                {{ Math.abs(totalNum.compareOrderAmount) || 0 }}%
              </span>
            </div>
          </div>
        </div>
        <div class="borderline"></div>
      </div>

      <div class="indexbox-top-item a-flex-rsbc">
        <div class="a-flex-csbfs a-h-100 a-ml-24">
          <span class="a-c-normal a-fs-14">总充电桩(台)/插槽数(台)</span>
          <div class="a-fs-18 a-fw-700 a-mtb-12">
            <span class="a-fs-24 a-ff-db">{{ deviceSlot.deviceNum || 0 }}</span>
            <span class="a-fw-700 a-fs-24 a-ml-08">/</span>
            <span class="a-fs-24 a-ff-db a-ml-5">{{ deviceSlot.slotNum || 0 }}</span>
          </div>
          <div class="a-flex-rsbc">
            <div class="a-flex-rfsc a-fs-12">
              <span class="a-c-second">同比</span>
              <img
                v-if="deviceSlot.compareDeviceNum2 < 0"
                src="../../../assets/icon/index-top-down.png"
                alt=""
                class="colortip"
              />
              <img v-else src="../../../assets/icon/index-top-up.png" alt="" class="colortip" />
              <span :class="deviceSlot.compareDeviceNum2 < 0 ? 'a-c-red' : 'a-c-green'">
                {{ Math.abs(deviceSlot.compareDeviceNum2) || 0 }}%
              </span>
            </div>
            <div class="a-flex-rfsc a-fs-12 a-ml-16">
              <span class="a-c-second">环比</span>
              <img
                v-if="deviceSlot.compareDeviceNum < 0"
                src="../../../assets/icon/index-top-down.png"
                alt=""
                class="colortip"
              />
              <img v-else src="../../../assets/icon/index-top-up.png" alt="" class="colortip" />
              <span :class="deviceSlot.compareDeviceNum < 0 ? 'a-c-red' : 'a-c-green'">
                {{ Math.abs(deviceSlot.compareDeviceNum) || 0 }}%
              </span>
            </div>
          </div>
        </div>
        <div class="borderline"></div>
      </div>
      <div class="indexbox-top-item a-flex-rsbc">
        <div class="a-flex-csbfs a-h-100 a-ml-24">
          <span class="a-c-normal a-fs-14">当前设备使用情况</span>
          <span class="a-fs-14 a-mtb-12">
            设备总数：
            <span class="a-fs-24 a-ff-db a-fw-700">{{ deviceUsedInfo.totalNum }}</span>
            台（使用中：
            <span class="a-fs-24 a-ff-db a-fw-700">{{ deviceUsedInfo.usingNum }}</span>
            台；未使用：
            <span class="a-fs-24 a-ff-db a-fw-700">
              {{ deviceUsedInfo.totalNum - deviceUsedInfo.usingNum }}
            </span>
            台）
          </span>
          <div class="cg-text-13px font-normal">
            在线：
            <span class="a-fs-24 a-ff-db a-fw-700">{{ deviceUsedInfo.onlineNum }}</span>
            台
            <span class="a-ml-12">
              离线：
              <span class="a-fs-24 a-ff-db a-fw-700">
                {{ deviceUsedInfo.offlineNum }}
              </span>
              台
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import util from "../../../utils/util.js";
import leDateChangeTimeIndex from "../../components/form/le-date-changeTime-index.vue";

export default {
  components: {
    "le-date-change-time-index": leDateChangeTimeIndex,
  },
  data() {
    return {
      activeT: 0,
      startTime: "",
      endTime: "",
      echarts: null,
      payTypeList: null,
      info: {
        compareOrderAmount: 0,
        compareOrderAmount2: 0,
        lastOrderAmount: 0,
        lastOrderAmount2: 0,
        orderAmount: 0,
      },
      totalNum: {
        compareOrderNum: 0,
        compareOrderNum2: 0,
        lastOrderNum: 0,
        lastOrderNum2: 0,
        orderNum: 0,
      },
      deviceSlot: {
        deviceNum: 0,
        slotNum: 0,
        lastDeviceNum: 0,
        lastSlotNum: 0,
        lastSlotNum2: 0,
        compareDeviceNum: 0,
        compareDeviceNum2: 0,
      },
      deviceUsedInfo: {
        totalNum: 0,
        onlineNum: 0,
        offlineNum: 0,
        errorNum: 0,
        usingNum: 0,
      },
      timeChange: "",
    };
  },
  watch: {
    startTime: {
      handler() {
        this.getData();
      },
      immediate: false,
    },
    endTime: {
      handler() {
        this.getData();
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    this.drawChart();
    this.getDeviceInfo();
  },
  methods: {
    getData() {
      if (!this.timer) {
        const that = this;
        this.timer = setTimeout(() => {
          that.handlerGetInfo(that.$Config.apiUrl.businessVolume, "info", that.info);
          that.handlerGetInfo(that.$Config.apiUrl.orderNumber, "totalNum", that.totalNum);
          that.handlerGetInfo(that.$Config.apiUrl.deviceSlot, "deviceSlot", that.deviceSlot);
          that.handlerPayType();
          that.timer = null;
        }, 100);
      }
    },

    getDeviceInfo() {
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.deviceUsedInfo,
          params: {
            startTime: this.startTime,
            endTime: this.endTime,
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.deviceUsedInfo = res.result.data[0];
          }
        });
    },

    drawChart(data = []) {
      const chartDom = this.$refs["echartsRef"];
      const option = {
        tooltip: {
          trigger: "item",
        },
        color: ["#3BA1FF", "#4DCB73", "#FF3030", "#FF9C00", "#FFD700"],
        legend: {
          top: "middle",
          height: "auto",
          left: "left",
          x: "left",
          orient: "vertical",
          width: "120",
          itemGap: 12,
          itemWidth: 8,
          itemHeight: 8,
          icon: "circle",
          textStyle: {
            fontSize: 12,
          },
          formatter: function (params) {
            let a;
            const b = [];
            let c;
            let total;
            data.forEach((item) => {
              b.push(item.value);
            });
            // console.log(b)
            if (b.length) {
              total = b.reduce((val, oldVal) => parseInt(val) + parseInt(oldVal));
              // console.log(total)
            } else {
              total = 0;
            }
            data.forEach((item) => {
              if (item.name == params) {
                if (total == 0) {
                  a = "${item.name}  0%";
                } else {
                  a = "${item.name}   ${((item.value / total) * 100).toFixed(2)}%";
                }
                c = eval("`" + a + "`");
              }
            });
            return c;
          },
        },
        series: [
          {
            name: "营业额比例",
            type: "pie",
            radius: ["57%", "75%"],
            center: ["75%", "50%"],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: "center",
                textBorderWidth: 0,
                formatter: function (params) {
                  return params.data.name.split(" ")[0];
                },
              },
            },

            emphasis: {
              label: {
                show: true,
                fontSize: "12",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: data.map((item) => (item = { ...item, value: item.value / 100 })),
          },
        ],
      };
      setTimeout(() => {
        this.echarts = this.$echarts.init(chartDom);
        this.echarts.setOption(option, true);
      }, 500);

      window.addEventListener("resize", () => {
        if (this.echarts) {
          this.echarts.resize();
        }
      });
    },
    //获取总营业额数据
    handlerGetInfo(url, str, obj) {
      this.$Axios
        ._post({
          url: url,
          params: {
            startTime: this.startTime,
            endTime: this.endTime,
          },
        })
        .then((res) => {
          let abj;
          if (res.result.code == 0) {
            if (res.result.data) {
              abj = res.result.data;
            } else {
              for (const i in obj) {
                obj[i] = 0;
              }
              abj = obj;
            }
            abj.orderAmount = util.numFormat(abj.orderAmount);
            this.$set(this, str, abj);
          } else {
            this.$message.error(res.result.msg);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    //获取支付方式数据
    handlerPayType() {
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.payType,
          params: {
            startTime: this.startTime,
            endTime: this.endTime,
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            if (res.result.data) {
              this.payTypeList = res.result.data.map((item) => {
                return {
                  name: item.payType,
                  value: Number(item.paySum) || 0,
                };
              });
              this.drawChart(this.payTypeList);
            }
          } else {
            this.$message.error(res.result.msg);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.indexbox {
  &-top {
    margin-top: 32px;

    &-item {
      &:nth-of-type(1) {
        width: 38%;
      }

      &:nth-of-type(2) {
        width: 19%;
      }

      &:nth-of-type(3) {
        width: 19%;
      }

      // height: 96px;

      .colortip {
        width: 16px;
        height: 16px;
      }

      .borderline {
        width: 1px;
        height: 96px;
        border: 1px solid;
        border-image: linear-gradient(
            180deg,
            rgba(200, 200, 200, 0),
            rgba(225, 225, 225, 0.82),
            rgba(151, 151, 151, 0)
          )
          1 1;
      }
    }
  }

  /deep/ .changeTime {
    > div {
      &:nth-of-type(2) {
        margin-left: 16px !important;
      }
    }
  }
}
</style>
