<template>
  <div class="indexbox a-h-100 a-flex-csbfs">
    <div class="a-flex-rsbc a-w-100">
      <div class="a-fs-16 a-fw-500 a-ff-pmp">本月商户营业额排行</div>
      <span class="a-c-normal a-fs-12">更新时间：{{ time }}</span>
    </div>
    <div class="indexbox-phb a-flex-cfssb a-h-100 a-w-100">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="a-flex-rsbc a-fs-14 a-w-100 a-mt-10"
        @click="handlerGoDetail(item.companyId)"
      >
        <div class="a-flex-rfsc">
          <div>
            <img
              v-if="index < 3"
              :src="require('../../../assets/icon/index-top' + (index + 1) + '.png')"
              alt=""
            />
            <span v-else>
              {{ index + 1 }}
            </span>
          </div>
          <span>{{ item.name }}</span>
        </div>
        <span>&yen;{{ util.numFormat(item.companySum) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import util from "../../../utils/util.js";
export default {
  data() {
    return {
      util: util,
      list: [],
      time: "",
    };
  },
  mounted() {
    this.handlerPhangList();
  },
  methods: {
    //获取支付方式数据
    handlerPhangList() {
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.companyTurnoverRank,
          params: {
            startTime: "",
            endTime: "",
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            if (res.result.data && res.result.data.length) {
              const shortList = [];
              res.result.data.map((item, index) => {
                if (index < 8) {
                  shortList.push(item);
                }
              });
              this.list = shortList;
              this.time = res.result.time;
            }
          } else {
            this.$message.error(res.result.msg);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    handlerGoDetail(id) {
      this.$router.push({
        path: "/franchisee/franchisee-detail",
        query: {
          code: id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.indexbox {
  &-phb {
    margin-top: 20px;

    > div {
      // margin-top: 10px;

      > div {
        > div {
          width: 68px;

          span {
            margin-left: 8px;
          }
        }

        > span {
          cursor: pointer;
        }

        img {
          height: 22px;
          width: 56px;
        }
      }
    }
  }
}
</style>
